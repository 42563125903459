"use client";

import Alert from "react-bootstrap/Alert";
import { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Authenticate from "../../components/Authenticate";
import Teams from "../../components/Teams";
import FuegoStatsTitle from "../../components/FuegoStatsTitle";
import { colorFuegoStatsBrown, colorFuegoStatsOrange } from "../../lib/colors";
import { SessionContext } from "../hooks/useSession";
import AppNavigation from "../../components/AppNavigation";
import { FaCircleExclamation } from "react-icons/fa6";

export default function Home() {
  const [showAuthModal, setShowAuthModal] = useState<boolean>();
  const { user, isLoading, sessionTeams } = useContext(SessionContext);
  const userIsUnauthenticated = user === null && isLoading === "Session Ready";
  const userReady = user !== null && isLoading === "Session Teams Ready";
  const hasTeams = sessionTeams && Object.keys(sessionTeams).length > 0;
  const isMounting =
    !userIsUnauthenticated &&
    isLoading !== "Session Teams Ready" &&
    sessionTeams === null;

  return (
    <>
      <AppNavigation path="/" />
      <FuegoStatsTitle includeLogo={true} isLoading={isMounting} />
      {isMounting ? null : userIsUnauthenticated || (userReady && !hasTeams) ? (
        <>
          <p className="text-center mt-4">
            <span
              className="fw-bold me-1"
              style={{ color: colorFuegoStatsOrange }}>
              No stream?
            </span>
            <span className="fw-bold" style={{ color: colorFuegoStatsBrown }}>
              No problem.
            </span>
          </p>
          <p className="text-center mt-2">
            <strong>Fuego Stats</strong> is a mobile-friendly gameflow
            statistics app for Ultimate focused on whole-team analytics. Stats
            entered by one user are rendered in real-time for coaches, players,
            and spectators at the field... and around the globe.
          </p>
        </>
      ) : null}
      {userReady && !hasTeams ? (
        <Alert className="w-100 my-4 text-center border-2" variant="warning">
          <h5 className="mb-2">
            <FaCircleExclamation className="text-warning me-1" />
            Have a Team?
          </h5>
          <p className="my-1">
            <strong className="me-1">
              Your profile, <u>{user?.email}</u>, is not linked to any Fuego
              Stats teams.
            </strong>
            Either use your team&rsquo;s direct-link url or ask your team admin
            to add you to a team.
          </p>
        </Alert>
      ) : null}
      {userIsUnauthenticated ? (
        <div className="d-flex justify-content-center mt-4">
          <Button
            onClick={() => setShowAuthModal(true)}
            variant="primary"
            className="w-75">
            Sign In
          </Button>
        </div>
      ) : null}
      <Teams teams={sessionTeams} isLoading={hasTeams && !userReady} />
      <Authenticate
        show={showAuthModal}
        onClose={() => setShowAuthModal(false)}
      />
    </>
  );
}
