import Link from "next/link";
import { useEffect, useContext } from "react";
import { UserTeams, UserTeam } from "../app/hooks/useSession";
import { SessionContext } from "../app/hooks/useSession";
import ListGroup from "react-bootstrap/ListGroup";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Collapse, Container } from "react-bootstrap";
import { FaComment, FaKey } from "react-icons/fa";
import { IconReport } from "./icons";
import {
  BiSolidFolder as FaFolder,
  BiSolidFolderPlus as FaFolderPlus,
  BiSolidFolderMinus as FaFolderMinus,
} from "react-icons/bi";
import { FaMagnifyingGlassChart } from "react-icons/fa6";

export default function Teams({
  teams,
  isLoading,
}: {
  teams: UserTeams;
  isLoading: boolean;
}) {
  const { toggleArchived } = useContext(SessionContext);

  useEffect(() => {
    document.title = `Fuego Stats`;
  }, []);

  const tableKeys = teams
    ? Object.keys(teams)
        ?.filter((k) => !teams[parseInt(k)].archived)
        .sort((a, b) =>
          teams[parseInt(a)].team.name > teams[parseInt(b)].team.name ? 1 : -1
        )
    : null;

  const archivedTeams = teams
    ? Object.keys(teams)
        ?.filter((k) => teams[parseInt(k)].archived)
        .sort((a, b) =>
          teams[parseInt(a)].team.name > teams[parseInt(b)].team.name ? 1 : -1
        )
        .map((k) => teams[parseInt(k)])
    : [];

  const toggleArchiveStatus = async (userteam: UserTeam) => {
    // toggle the archive state for the user-team
    if (userteam !== null) {
      toggleArchived(userteam);
    }
  };

  return (
    <Collapse className="my-2" in={!isLoading && tableKeys?.length > 0}>
      <div className="pt-3">
        <ListGroup>
          {(tableKeys ?? []).map((id, index) => (
            <ListGroup.Item key={`${index}`}>
              <TeamRow
                team={id === null ? null : teams[parseInt(id)]}
                key={id ?? index}
                toggleArchiveAction={
                  // don't allow archiving of last non-archived team
                  tableKeys.length === 1
                    ? null
                    : () => toggleArchiveStatus(teams[parseInt(id)])
                }
              />
            </ListGroup.Item>
          ))}
        </ListGroup>

        {archivedTeams.length > 0 ? (
          <Accordion className="mt-4">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h6 className="text-secondary my-0 d-flex align-items-center">
                  <FaFolder className="me-2" />
                  Archived Teams
                  <div className="d-inline ms-2">
                    <Badge bg="secondary">{archivedTeams?.length}</Badge>
                  </div>
                </h6>
              </Accordion.Header>
              <Accordion.Body className="p-0">
                <ListGroup variant="flush">
                  {archivedTeams.map((team, index) => (
                    <ListGroup.Item key={`${index}`}>
                      <TeamRow
                        key={team.id ?? index}
                        team={team}
                        toggleArchiveAction={() => toggleArchiveStatus(team)}
                      />
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ) : null}
        <div style={{ marginBottom: 60 }} />
      </div>
    </Collapse>
  );
}

function TeamRow({
  team,
  toggleArchiveAction,
}: {
  team: UserTeam | null;
  toggleArchiveAction: () => void | null;
}) {
  const { userHasRoles } = useContext(SessionContext);
  const {
    admin: hasAdminRole,
    edit: hasEditRole,
    review: hasReviewRole,
    chat: hasChatRole,
  } = userHasRoles(team?.team_id);

  return (
    <Container fluid className="px-0 d-flex justify-content-between">
      <div className="d-flex align-items-center">
        <Link href={`/t/${team?.team.id}/`} className="text-truncate">
          {team?.team.name || <Skeleton inline={false} width={250} />}
        </Link>
      </div>
      <div>
        {hasAdminRole ? <FaKey className="me-1 text-success" /> : null}
        {hasAdminRole || hasEditRole ? (
          <IconReport className="me-1 text-success" />
        ) : null}
        {hasAdminRole || hasEditRole || hasReviewRole ? (
          <FaMagnifyingGlassChart className="me-1 text-success" />
        ) : null}
        {hasAdminRole || hasEditRole || hasReviewRole || hasChatRole ? (
          <FaComment className="me-1 text-success" />
        ) : null}
        {(hasChatRole || hasEditRole || hasReviewRole || hasAdminRole) &&
        toggleArchiveAction ? (
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => toggleArchiveAction()}>
            {team.archived ? <FaFolderMinus /> : <FaFolderPlus />}
          </Button>
        ) : null}
      </div>
    </Container>
  );
}
