import { IconFuegoStats } from "./icons";
import { Staatliches } from "next/font/google"; // https://fonts.google.com/variablefonts
export const FuegoStatsAppFont = Staatliches({
  weight: "400",
  subsets: ["latin"],
});

export default function FuegoStatsTitle({
  includeLogo = false,
  isLoading = false,
}: {
  includeLogo?: boolean;
  isLoading?: boolean;
}) {
  return (
    <>
      {includeLogo ? (
        <div
          className="d-flex justify-content-center mt-4 mb-2"
          style={{ marginTop: 25 }}>
          <IconFuegoStats className={isLoading ? "animation-beat" : ""} />
        </div>
      ) : null}
      <h2 className={"text-center " + FuegoStatsAppFont.className}>
        Fuego Stats
      </h2>
    </>
  );
}
